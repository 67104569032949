body {
	margin: 0;
	font-family: 'DM Sans', sans-serif;
}
.slider {
	height: 100vh;
	margin-top: -50px;
	position: relative;
}
@media (max-width: 600px) {
	.slider {
		height: 130vh;
	}
}
@media (min-width: 600px) and (max-width: 800px) {
	.slider {
		height: 154vh;
	}
}
.slider .list .item {
	position: absolute;
	inset: 0 0 0 0;
	overflow: hidden;
	opacity: 0;
	transition: 0.5s;
}
.slider .list .item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.slider .list .item::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	background-image: linear-gradient(
		to right,
		rgb(4 8 10 / 91%) 40%,
		transparent
	);
}

.bg {
	background-image: linear-gradient(
		to right,
		#041b28 10.64%,
		rgba(2, 15, 22, 0.548011) 46.91%,
		rgba(0, 0, 0, 0) 99.42%
	);
}
.slider .list .item .content {
	position: absolute;
	left: 12%;
	top: 15%;
	width: 50%;
	max-width: 80%;
	z-index: 1;
}
@media (max-width: 500px) {
	.slider .list .item .content {
		width: 90%;
	}
}
.slider .list .item .content p:nth-child(1) {
	/* text-transform: uppercase;
	letter-spacing: 10px; */
}
.slider .list .item .content h2 {
	font-size: 100px;
	margin: 0;
}
.slider .list .item.active {
	opacity: 1;
	z-index: 10;
}
@keyframes showContent {
	to {
		transform: translateY(0);
		filter: blur(0);
		opacity: 1;
	}
}
.slider .list .item.active p:nth-child(1),
.slider .list .item.active h2,
.slider .list .item.active p:nth-child(3) {
	transform: translateY(30px);
	filter: blur(20px);
	opacity: 0;
	animation: showContent 0.5s 0.7s ease-in-out 1 forwards;
}
.slider .list .item.active h2 {
	animation-delay: 1s;
}
.slider .list .item.active p:nth-child(3) {
	animation-duration: 1.3s;
}
.arrows {
	position: absolute;
	top: 30%;
	right: 50px;
	z-index: 100;
}
.arrows button {
	background-color: #eee5;
	border: none;
	font-family: monospace;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	font-size: x-large;
	color: #eee;
	transition: 0.5s;
	border-radius: 25px;
}
.arrows button:hover {
	background-color: #eee;
	color: black;
}
.thumbnail {
	position: absolute;
	bottom: 50px;
	z-index: 11;
	display: flex;
	gap: 10px;
	width: 100%;
	height: 250px;
	padding: 0 50px;
	box-sizing: border-box;
	overflow: auto;
	justify-content: center;
}
.thumbnail::-webkit-scrollbar {
	width: 0;
}
.thumbnail .item {
	width: 150px;
	height: 220px;
	filter: brightness(0.5);
	transition: 0.5s;
	flex-shrink: 0;
}
.thumbnail .item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}
.thumbnail .item.active {
	filter: brightness(1.5);
}
.thumbnail .item .content {
	position: absolute;
	inset: auto 10px 10px 10px;
}
@media screen and (max-width: 678px) {
	.thumbnail {
		justify-content: start;
	}
	.slider .list .item .content h2 {
		font-size: 60px;
	}
	.arrows {
		top: 10%;
	}
}
