.textSpan {
	color: white;
	// background: url('./../../../assets/images/textBackground.svg') no-repeat;
}
.navbar {
	color: white;
	// background: url('./../../../assets/images/Fill.svg') no-repeat;
}
.textSpanD {
	color: white;
	// background: url('./../../../assets/images/textBackground.svg') no-repeat;
	background-position: left;
	background-repeat: no-repeat;
	background-size: contain;

	&.aut {
		background-size: 200px;
	}
	&.abp {
		overflow: visible !important;
		background-size: 80px;
	}
}

@media screen and (max-width: 325px) {
	.textSpan {
		&.one {
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: center;
			background-position: center center;
		}
	}
}

//Our brief History page
.OurStorySection {
	position: relative;

	.sliderBtnBox {
		bottom: 4vh;
		right: 0;
		position: absolute;
		width: 75px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		z-index: 20;
		right: 37px;

		img {
			height: 30px;
			width: 30px;
		}
	}

	.slideCon {
		position: absolute;
		bottom: 0;
	}

	.sliderWhole {
		height: 60vh;
		width: 200vw;
		position: relative;
		display: flex;
		align-items: flex-end;
		transition: all 0.5s ease;
		// background-color: rgba(172, 245, 37, 0.637);

		&.one {
			left: 95vw;
		}

		&.two {
			left: 53vw;
		}

		&.three {
			left: 3vw;
		}

		&.four {
			left: -47vw;
		}
		&.onet {
			left: 88vw;
		}

		&.twot {
			left: 53vw;
		}

		&.threet {
			left: 14vw;
		}

		&.fourt {
			left: -22vw;
		}
	}

	.schSingle {
		width: 50vw;
		height: 100%;
		bottom: 0;
		right: 0;
		position: relative;
		// background-color: rgba(90, 88, 88, 0.171);

		h3 {
			position: absolute;
			bottom: 2.2vh;
			left: -110px;
			margin: 0;
			padding: 0;
			font-family: Roboto Slab;
			font-style: normal;
			font-weight: bold;
			font-size: 48px;
			line-height: 54px;
			/* identical to box height, or 112% */
			text-transform: capitalize;
			color: white;
			opacity: 0;
			transition: all 0.5s ease;
		}

		&.active {
			h3 {
				opacity: 1;
			}

			.schLine {
				.schLineCircle {
					background-color: #00325b;
					border: 1px solid #e6185600;

					h1 {
						color: white;

						opacity: 3;
					}

					h2 {
						opacity: 1;
					}
				}
			}
		}

		.schLine {
			height: 100%;
			width: 2px;
			background-color: #00325b;
			position: relative;

			.dateTag {
				position: absolute;
				bottom: 0;
				left: -29.333vw;
				margin: 0;
				padding: 0;
				bottom: 3.1vh;
				font-family: 'Roboto Slab';
				font-style: normal;
				font-weight: bold;
				font-size: 48px;
				line-height: 54px;
				text-transform: capitalize;
				color: white;
			}

			.schLineCircle {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				// background-color: #00325B; on active
				background: #e6f0f7;
				border: 1px solid white;
				position: absolute;
				left: -9px;
				transition: all 0.5s ease;

				h1 {
					position: absolute;
					top: -4.5px;
					left: 25px;
					font-size: 19px;
					font-family: 'Roboto Slab';
					// color: white; on act
					color: #00325b;
					opacity: 0.3;
					margin: 0;
					padding: 0;
					transition: all 0.5s ease;
				}

				h2 {
					position: absolute;
					position: absolute;
					width: 30vw;
					top: 25px;
					left: 25px;
					font-size: 12px;
					font-family: 'Montserrat';
					color: #00325b;
					opacity: 0;
					// opacity:1;
					margin: 0;
					padding: 0;
					font-weight: 600;
					line-height: 18px;
					transition: all 0.5s ease;
				}
			}
		}
	}
	.schSingles {
		width: 38vw;
		height: 100%;
		bottom: 0;
		right: 0;
		position: relative;
		// background-color: rgba(90, 88, 88, 0.171);

		h3 {
			position: absolute;
			bottom: 11.2vh;
			left: -223px;
			margin: 0;
			padding: 0;
			font-family: Roboto Slab;
			font-style: normal;
			font-weight: bold;
			font-size: 48px;
			line-height: 54px;
			/* identical to box height, or 112% */
			text-transform: capitalize;
			color: white;
			opacity: 0;
			transition: all 0.5s ease;
		}

		&.active {
			h3 {
				opacity: 1;
			}

			.schLine {
				.schLineCircle {
					background-color: #00325b;
					border: 1px solid #e6185600;

					h1 {
						color: white;

						opacity: 3;
					}

					h2 {
						opacity: 1;
					}
				}
			}
		}

		.schLine {
			height: 100%;
			width: 2px;
			background-color: #00325b;
			position: relative;

			.dateTag {
				position: absolute;
				bottom: 0;
				left: -29.333vw;
				margin: 0;
				padding: 0;
				bottom: 3.1vh;
				font-family: 'Roboto Slab';
				font-style: normal;
				font-weight: bold;
				font-size: 48px;
				line-height: 54px;
				text-transform: capitalize;
				color: white;
			}

			.schLineCircle {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				// background-color: #00325B; on active
				background: #e6f0f7;
				border: 1px solid white;
				position: absolute;
				left: -9px;
				transition: all 0.5s ease;

				h1 {
					position: absolute;
					top: -4.5px;
					left: 25px;
					font-size: 19px;
					font-family: 'Roboto Slab';
					// color: white; on act
					color: #00325b;
					opacity: 0.3;
					margin: 0;
					padding: 0;
					transition: all 0.5s ease;
				}

				h2 {
					position: absolute;
					position: absolute;
					width: 20vw;
					top: 25px;
					left: 25px;
					font-size: 12px;
					font-family: 'Montserrat';
					color: #00325b;
					opacity: 0;
					// opacity:1;
					margin: 0;
					padding: 0;
					font-weight: 600;
					line-height: 18px;
					transition: all 0.5s ease;
				}
			}
		}
	}
}

//Usps section
.OurUspsSection {
	.scroolSliderUsps {
		width: 100vw;
		height: 100%;
		// background-color: rgba(255, 0, 0, 0.486);
		display: flex;
		align-items: center;
		overflow: auto;

		&::-webkit-scrollbar {
			width: 0;
		}

		.uspsCard {
			margin: 0 10px;
			min-width: 220px;
			height: 208px;
			background: #fafafa;
			// border: 1px solid black;
			box-shadow: 10px 10px 30px rgba(3, 98, 175, 0.1);
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: relative;
			transition: all 0.7s ease;

			&.true {
				height: 248px;

				.uspsCardLine {
					height: 280px;
				}

				.uspsCardCircle {
					top: 3vh;
					transform: translateY(0%);
					border: 1px solid white;
				}

				.uspsITxt {
					bottom: 3vh;
				}

				.uspsTT {
					bottom: 40%;
					color: white;
				}
			}

			.uspsTT {
				position: absolute;
				bottom: 20%;
				color: #00325b;
				transition: all 0.5s ease;
			}

			.uspsCardInCon {
				// overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				background-color: #fafafa;
				flex-direction: column;
				position: relative;
				z-index: 6;
				padding: 20px;
				border-radius: 20px;
			}

			.uspsCardLine {
				position: absolute;
				height: 200px;
				width: 2px;
				border-radius: 5%;
				background-color: white;
				z-index: 1;
				transition: all 0.7s ease;
			}

			.uspsITxt {
				display: flex;
				position: absolute;
				width: 85%;
				bottom: -15vh;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				transition: all 0.8s ease;
			}

			.uspsCardCircle {
				position: absolute;
				top: 45%;
				transform: translateY(-55%);
				width: 80px;
				height: 80px;
				border-radius: 50%;
				border: 1px solid #0362af;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.5s ease;

				h1 {
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 36px;
					text-align: center;
					letter-spacing: 0.15px;

					color: #00325b;
				}
			}
		}
		.uspsCards {
			margin: 0 10px;
			min-width: 300px;
			height: 300px;
			// background: #fafafa;
			// border: 1px solid black;
			box-shadow: 10px 10px 30px rgba(3, 98, 175, 0.1);
			border-radius: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: relative;
			transition: all 0.7s ease;

			&.true {
				height: 340px;

				.uspsCardLine {
					height: 400px;
					position: absolute;
					z-index: -1;
				}

				.uspsCardCircle {
					top: 3vh;
					transform: translateY(0%);
					border: 1px solid white;
				}

				.uspsITxt {
					bottom: 5vh;
				}

				.uspsTT {
					bottom: 36%;
					color: white;
				}
			}

			.uspsTT {
				position: absolute;
				bottom: 13%;
				color: #00325b;
				transition: all 0.5s ease;
			}

			.uspsCardInCon {
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				background-color: white;
				flex-direction: column;
				position: relative;
				z-index: 6;
				padding: 20px;
				border-radius: 20px;
			}

			.uspsCardLine {
				position: absolute;
				height: 290px;
				width: 2px;
				border-radius: 5%;
				background-color: white;
				z-index: 1;
				transition: all 0.7s ease;
			}

			.uspsITxt {
				display: flex;
				position: absolute;
				width: 85%;
				bottom: -15vh;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				transition: all 0.8s ease;
			}

			.uspsCardCircle {
				position: absolute;
				top: 45%;
				transform: translateY(-55%);
				width: 150px;
				height: 150px;
				border-radius: 50%;
				border: 1px solid #0362af;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.5s ease;

				h1 {
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 36px;
					text-align: center;
					letter-spacing: 0.15px;

					color: #00325b;
				}
			}
		}
	}
}

// for header mobile Web
.headerMW {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100vw;
	padding: 10px 25px;
	// padding-top: 33px;
	z-index: 30;
	position: relative;

	// background-color:rgb(250, 194, 194);
	h1 {
		margin: 0;
		padding: 0;
	}

	img {
		// z-index: 25;
	}

	.hamburgHMW {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background: rgba(255, 255, 255, 0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		right: 25px;
		transition: all 0.5s ease;
		z-index: 25;

		&.active {
			background: rgba(250, 250, 250, 0.2);
			width: 40px;
			height: 40px;

			.hamburgHMWCon {
				width: 10px;
				height: 10px;

				.line {
					background-color: rgb(77, 165, 40);

					&.Two {
						width: 0;
					}

					&.One {
						top: 40%;
						width: 100%;
						transform: rotate(45deg);
					}

					&.Three {
						top: 40%;
						width: 100%;
						transform: rotate(-45deg);
					}
				}
			}
		}

		.hamburgHMWCon {
			width: 22.5px;
			height: 15px;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.5s ease;
		}

		.line {
			height: 2px;
			// border-radius: 50px;
			background-color: rgb(77, 165, 40);
			transition: all 0.5s ease;
			position: absolute;

			&.Two {
				top: 50%;
				transform: translateY(-50%);
				width: 100%;
			}

			&.One {
				top: 0%;
				left: 0px;
				width: 80%;
			}

			&.Three {
				top: 100%;
				// bottom: 0px;
				transform: translateY(-50%);
				right: 0;
				width: 80%;
			}
		}
	}

	.hamDrop {
		position: absolute;
		z-index: 21;
		left: 0;
		top: -105vh;
		width: 100vw;
		height: 100vh;
		background-color: white;
		transition: all 0.5s ease;

		&.active {
			top: 0vh;
		}

		img {
			margin-top: 33px;
			margin-left: 28px;
		}

		.topul {
			margin-top: 10vh;

			li {
				display: flex;
				list-style: none;
				font-family: Montserrat;
				font-style: normal;
				font-weight: bold;
				font-size: 36px;
				line-height: 44px;
				color: #ffffff;
				margin-bottom: 5vh;
				opacity: 0.5;
				// background: url('./../../../assets/images/textBackground.svg') no-repeat;

				&.activeli {
					opacity: 1;
				}
			}
		}

		.hamDropBtm {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			position: absolute;
			bottom: 0;

			h5 {
				margin: 0;
				padding: 0;
				font-style: normal;
				font-weight: bold;
				font-size: 14px;
				line-height: 21px;
				/* identical to box height, or 150% */

				text-align: center;

				color: #ffffff;
			}

			.btmul {
				width: 100%;
				// margin-top: 10vh;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0;
				margin: 18px 0;

				li {
					list-style: none;

					color: #ffffff;
					margin-right: 31px;

					&:last-child {
						margin-right: 0;
					}
				}
			}

			p {
				margin: 0;
				padding: 0;
				margin-bottom: 8px;
				font-weight: 600;
				font-size: 12px;
				line-height: 21px;
				/* identical to box height, or 175% */

				text-align: center;

				color: #ffffff;
			}
		}
	}
}

//for footer
.FooterMain {
	h3 {
		font-size: 14px;
		font-weight: bold;
		margin: 0;
		padding: 0;
		color: #fff;
	}

	h2 {
		margin: 0;
		padding: 0;
		color: #fff;
		font-size: 18px;
		line-height: 27px;
	}

	h4 {
		margin: 0;
		padding: 0;
		color: #8098ad;
		font-size: 14px;
		line-height: 21px;
		margin-bottom: 33px;
	}

	p {
		margin: 0;
		padding: 0;
		margin-bottom: 8px;
		font-weight: 600;
		font-size: 12px;
		line-height: 21px;
		text-align: left;
		color: #ffffff;
		opacity: 0.65;
	}

	.navUl {
		display: flex;
		margin: 22px 0;
		padding: 0;

		li {
			color: #fff;
			list-style: none;
			color: #ffffff;
			margin-right: 14px;
			font-weight: bold;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.termsAndUseUl {
		display: flex;
		margin: 22px 0;
		padding: 0;

		.divLine {
			margin-right: 10px;
			height: 22px;
			width: 1.2px;
			background-color: #fff;
		}

		li {
			color: #fff;
			list-style: none;
			color: #ffffff;
			margin-right: 10px;
			font-size: 14px;
			font-weight: normal;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.socialLinksFooter {
		ul {
			display: flex;
			margin: 22px 0;
			padding: 0;
			margin-bottom: 12px;

			li {
				font-size: 20px;
				list-style: none;
				color: #ffffff;
				margin-right: 31px;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

.LPSectionSix {
	.imgSec6Container {
		position: relative;
		left: -10vw;
		width: 400vw;
		margin-top: 4vh;
		display: flex;
		transition: all 0.5s ease;

		&.slideIOne {
			left: -10vw;
		}

		&.slideITwo {
			left: -110vw;
		}

		&.slideIThree {
			left: -210vw;
		}

		&.slideIFour {
			left: -310vw;
		}
	}

	.isc6 {
		width: 100vw;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.dataSec6ConTop {
		margin-top: 3vh;
		display: flex;
		width: 300%;
		position: relative;
		transition: all 0.5s ease;

		&.slideTOne {
			left: 0%;
		}

		&.slideTTwo {
			left: -15%;
		}

		&.slideTThree {
			left: -65%;
		}

		&.slideTFour {
			left: -90%;
		}

		button {
			&.activeBtn {
				border-bottom: 1px solid red;
				opacity: 1;
			}
		}
	}

	.dataSec6ConTopAboutP {
		margin-top: 3vh;
		display: flex;
		align-items: center;
		justify-content: space-between;

		width: 100%;
		position: relative;
		transition: all 0.5s ease;

		button {
			&.activeBtn {
				border-bottom: 1px solid red;
				opacity: 1;
			}
		}
	}

	.dataSec6ConBottom {
		position: relative;
		transition: all 0.5s ease;
		display: flex;
		width: 400vw;

		&.slideOne {
			left: -10vw;
		}

		&.slideTwo {
			left: -110vw;
		}

		&.slideThree {
			left: -210vw;
		}

		&.slideFour {
			left: -310vw;
		}
	}
}

// section Seven

.LPSectionSeven {
	overflow: hidden;
	width: 100vw;

	.secSevenCardMainCon::-webkit-scrollbar {
		width: 0;
	}

	.secSevenCardMainCon {
		padding: 5vh 0;
		padding-bottom: 4vh;
		width: 100vw;
		display: flex;
		position: relative;
		transition: all 0.5s ease;
		left: -10vw;
		overflow-x: auto;

		.secSevenCard {
			min-width: 60vw;
			margin: 0 10px;
			padding: 25px 20px;
			box-shadow: 10px 10px 30px rgba(3, 98, 175, 0.1);
			border-radius: 20px;
			position: relative;

			.secSevenCardBtm {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: space-between;
			}

			.cardBt {
				position: relative;
			}

			.topMarker {
				position: absolute;
				top: -5%;
			}

			.btmMarker {
				position: absolute;
				bottom: -4%;
				-webkit-transform: scaleX(-1);
				transform: scaleX(-1);
			}
		}
	}
}

.LPSectionEight {
	.accordianDiv {
		.accordionHead {
			margin: 0;
			padding-left: 4vw;
			font-size: 16px;
			font-weight: 700;
			color: #000000;
			transition: all 0.3s ease;
		}

		.MuiAccordionSummary-content {
			p {
				color: #00325b;
			}

			&.Mui-expanded {
				p {
					color: white;
				}
			}
		}

		.accordionSummaryDiv {
			flexdirection: 'row-reverse';
		}

		.MuiAccordion-root {
			box-shadow: none;
		}

		.MuiSvgIcon-root {
			width: 1.5em;
			height: 1.5em;
			color: #000;
		}

		.MuiSvgIcon-root {
			color: #00325b;
		}

		.MuiAccordionSummary-expandIcon {
			padding: 0;
			position: relative;
			left: -9px;
			transform: rotate(0deg);

			&.Mui-expanded {
				color: white;
				transform: rotate(90deg);

				.MuiSvgIcon-root {
					color: white;
				}
			}
		}

		.MuiAccordionDetails-root {
			padding: 0;
		}
	}
}

.LPSectionNine {
	overflow: hidden;
	margin-bottom: 5vh;

	.mapsample {
		margin-top: 2vh;
		position: relative;
		left: -10vw;
	}
}

.LPSectionTen {
	.MuiCheckbox-root {
		color: white;
	}
	.MuiCheckbox-colorPrimary.Mui-checked {
		color: white;
	}
}
